@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


@keyframes scroll {
	0% {
		transform: translateY(0);
	}
	30% {
		transform: translateY(100px);
	}
}

svg #wheel {
	animation: scroll ease 1.5s infinite;
}


.image-investigator{
	filter: grayscale(100%);
}

.imagen-investigador{
	margin: 0 auto!important;
}