.card-modal{
    width: 350px;
    height: 360px;
}

.ReactModal__Overlay{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ReactModal__Overlay--after-open{
    background-color: rgba(122, 122, 122, 0.75)!important;
}

.button_modal{
    background-color: #000;
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: #fff;
    padding: .5rem;
    border-radius: 8px;
}

.modal_caop{
    width: 450px;
    background-color: #fff;
    border-radius: 1rem;
    padding: 1.5rem;
    margin: 0 auto;
}

.button_slider{
    background-color: #000;
    padding: .25rem 0.5rem;
    margin: 1rem 10px 0  0;
    color: #fff;
}